import React from 'react';
import {
  
  InputControl,
  
} from "formik-chakra-ui";
import { Input } from '@chakra-ui/react'

function InputDateField(props) {
	const {name,inputValue,title,onChange} = props
	const [value, setValue] = React.useState('')
	const handleChange = (event) => {
		setValue(event.target.value)
		if(onChange){
			onChange(name,event.target.value)	
		}	
	}
	return(
		<>
			<div className="field_section">
					
				
				<InputControl inputProps={props.inputProps} name={name} label={title} onChange={handleChange} />
			</div>
			
		</>
	);
}

export default InputDateField;