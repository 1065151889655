import React,{useEffect,useState} from 'react';
import CustomModal from '../../components/CustomModal'
import CustomModalLink from '../../components/CustomModalLink'
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
  Button,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  useDisclosure,
} from '@chakra-ui/react'
import Create from './Create'
import Edit from './Edit'
import { fetchWrapper } from '../../helpers';

function List(){
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [activeTab, setActiveTab] = useState(0)
	
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)

  const [list,setList] = useState([])
  useEffect(() => {
        loadList()
       
       
        
    }, []);

  const loadList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/project-list`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows)
  }

  const meClose = () => {
  		
		   onClose();
		   
	}
	
	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}

	const meEdit = (rs) => {
		// setIsOpenEdit(true);
		// setModalEditView(<Edit rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)		
	}

	const meDelete = async (rs) => {
      const aa = window.confirm("Are you sure delete this record");
      if(aa){
        
        const url = `${process.env.REACT_APP_API_URL}/api/project-delete/${rs.id}`;
        await fetchWrapper.delete(url) 
        loadList() 
      }
  }

  const filteredList = () => {
   if (activeTab === 0) {
            return list.filter(project => project.status_id === 1);
        } else if (activeTab === 1) {
            return list.filter(project => project.status_id === 2);
        } else {
            return list.filter(project => project.status_id === 10);
        }
  }

  return(
    <>
    	<Box px="50px" py="20px">
				<Heading fontSize="32px" fontWeight="300" fontFamily="'Montserrat', sans-serif" color="#000">Projects</Heading>
			</Box>
    	
      <div className="page_section">
	    				
				<HStack justifyContent="space-between" mb="10px">
					<Box>
						<Tabs position="relative" variant="unstyled"  onChange={(index) => setActiveTab(index)}>
					    <TabList className="TabsList">
					      <Tab>Active Project</Tab>
					      <Tab>On Hold</Tab>
					      <Tab>Completed</Tab>
					    </TabList>
					   
					    
					  </Tabs>
					</Box>

					<Box>
						<CustomModal
              showModalButtonText="Start New Project"
              modalHeader="Start New Project"
              isOpen={isOpen}
           		onClose={onClose}
           		onOpen={onOpen}
              modalBody=<Create meClose={meClose} loadList={loadList} />
            />
					</Box>		
				</HStack>

				<div className="table_section">
					<TableContainer width="100%">
					  <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
					    <Thead>
					      <Tr>
					        <Th>Project Name</Th>
				         	<Th>Project Type</Th>
				          <Th>Team</Th>
				          <Th>Business Unit</Th>
					        <Th>Project Manager</Th>
					        <Th></Th>
					      </Tr>
					    </Thead>
					    <Tbody>	
					    {filteredList().map((rs,index)=>{
						    return(
						      <Tr key={index}>
						        <Td><Link to={`/edit/${rs.id}`} state={{rs:rs}}>{rs.name}</Link></Td>
						        <Td>{rs?.Project_Type?.name}</Td>
						        <Td>{rs?.Team?.name}</Td>
						        <Td>{rs?.Business_Unit?.name}</Td>
						        <Td>{rs?.Project_Manage?.name}</Td>
						        <Td>
						          <HStack mb="5px" mr="65px" justifyContent="right" >
						            {/* Your edit and delete buttons */}
						          </HStack>
						        </Td>
						      </Tr>
						    )
						  })}				    
					      
					    </Tbody>
					    
					  </Table>
					</TableContainer>
				</div>
			</div>

      
		</>
    )
}

export default List;