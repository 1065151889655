import React,{useEffect,useState} from 'react';
import { HStack, Box, Image, Text, Button } from '@chakra-ui/react';
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react'
import { Link } from "react-router-dom";
import InnerNav from './InnerNav';

function LeftNav({loadPage}){
 	const [selectedLink, setSelectedLink] = useState("Localization");

 	const handleLinkClick = (link) => {
    setSelectedLink(link);
    // You can perform additional actions here, such as loading a specific page
    loadPage(link);
  };
	return(
		<>
		 <HStack>
			<Box height="100vh" borderRightWidth="1px" borderStyle="solid" borderColor="#ccc" py="20px"  width="50%">
				<UnorderedList m="0px" p="0px" listStyleType="none">

				  <ListItem>
				  	<Link className={`setting_nav_link ${selectedLink === "Localization" ? "active" : ""}`}
            onClick={() => handleLinkClick("Localization")}>
            	Localization
            </Link>
				  </ListItem>

				   <ListItem mt="10px">
				  	<Link className={`setting_nav_link ${selectedLink === "Projects" ? "active" : ""}`}
            onClick={() => handleLinkClick("Projects")}>Projects</Link>
				  </ListItem>

				  <ListItem mt="10px">
				  	<Link className={`setting_nav_link ${selectedLink === "Employees" ? "active" : ""}`}
            onClick={() => handleLinkClick("Employees")}>Employees</Link>
				  </ListItem>



				  <ListItem mt="10px">
				  	<Link className={`setting_nav_link ${selectedLink === "Timesheets" ? "active" : ""}`}
            onClick={() => handleLinkClick("Timesheets")}>Timesheets</Link>
				  </ListItem>


				  <ListItem mt="10px" w="220px">
				  	<Link className={`setting_nav_link ${selectedLink === "Roles" ? "active" : ""}`} 
            onClick={() => handleLinkClick("Roles")}>Role & Permissions</Link>
				  </ListItem>

				  


				</UnorderedList>
			</Box>

			<Box width="50%">
          <InnerNav linked={selectedLink} loadPage={loadPage} />
      </Box>
     </HStack>   
		</>
	);
}

export default LeftNav;