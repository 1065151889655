import React,{useEffect,useState} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
} from '@chakra-ui/react'
import {InputText,Form,InputDateField,SelectField,TextAreaField} from '../../../components/form'
import { fetchWrapper } from '../../../helpers';
import * as Yup from 'yup';


function CreateConsultant ({meCloseEdit}){
	const [job_role, setJobRole] = useState([])
	const [emp, setEmployee] = useState([])

	const [formData, setFormData] = useState({
	   	emp_id:'',
	   	job_role_id:'',
	   	start_date:'',
	   	end_date:'',
	   	worked_days:'0',
	   	dayhours:'',
	   	notes:'',
  	});

  	 const FormSchema = Yup.object().shape({
	   emp_id: Yup.string().required('Required'),
	   job_role_id: Yup.string().required('Required'),
	   start_date: Yup.string().required('Required'),
	   end_date: Yup.string().required('Required'),
	   dayhours: Yup.string().required('Required'),
	   notes: Yup.string().required('Required'),

	});

  	useEffect(()=>{
		loadJobList();
		loadEmplist();
	},[])

	const loadJobList = async()=>{
	 	const url = `${process.env.REACT_APP_API_URL}/api/job-role-list`;
	  	const data = await fetchWrapper.get(url)
	  	setJobRole(data.rows)
	}
	const loadEmplist = async()=>{
	 	const url = `${process.env.REACT_APP_API_URL}/api/cmd/all-employee`;
	  	const data = await fetchWrapper.get(url)
	  	setEmployee(data.rows)
	}


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		 let  data = values	
	     console.log(data)
  		 
	}

	return(
		<>
		<Form
            enableReinitialize
            validationSchema={FormSchema}
            initialValues={formData}
            onSubmit={onSubmit}
	 	>
			<HStack w="100%">
	 		
	 			<Box w="50%">
	 				<SelectField name="emp_id" title="Employee"  list={emp} />
	 			</Box>
	 			<Box w="50%">
	 				<SelectField  name="job_role_id" title="Role in Project" list={job_role}  />
	 			</Box>
	 		</HStack>


	 		<HStack w="100%">
	 			<Box w="50%">
	 				<InputDateField inputProps={{type:'date'}} name="start_date" title="Start Date"  />
	 			</Box>
	 			<Box w="50%">
	 				<InputDateField inputProps={{type:'date'}} name="end_date" title="End Date"  />
	 			</Box>
	 			<Box w="50%">
	 				<InputText  name="worked_days" title="Working Days" inputProps={{ disabled: true }}  />
	 			</Box>
	 		</HStack>


	 		<HStack w="100%">	 
	 			<Box w="40%">
	 				<InputText  name="dayhours" title="Hours per Day" />
	 			</Box>
	 			<Box w="30%">
	 				<InputText  name="Total Hours" title="Total Hours" inputProps={{ disabled: true }}  />
	 			</Box>
	 		</HStack>


 			<Box w="100%">
 				<TextAreaField  name="notes" title="Notes" />
 			</Box>


	 		<HStack w="40%">
	 		
	 			<Box me="5px">
	 				<Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif"
			            mb={4}
			            
			            type='submit'
	          		>
	           	Save
	          		</Button>
	 			</Box>
	 			<Box w="50%" >
	 				<Button py="10px" px="25px" color="#787878" bg="#fff" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif" border="1px solid #CCCCCC"
			            mb={4}
			            onClick={meCloseEdit}
			            
			            type='button'
	          		>
	           		Cancel
	          		</Button>
	 			</Box>
	 		</HStack>

 		</Form>	
		</>
	)
}

export default CreateConsultant;