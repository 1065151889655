import React,{useEffect,useState} from 'react';
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField} from '../../../components/form'
import { fetchWrapper } from '../../../helpers';

import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
} from '@chakra-ui/react'
import axios from 'axios';


function UploadFile({meCloseEdit,project_id}) {
	const [image, setImage] = useState({});
	const [folderList, setFolderList] = useState([]);

	const [formData, setFormData] = useState({
    	name:"",
    	folder_id:"",

  	});

  	const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   folder_id: Yup.string().required('Required'),
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		const url = `${process.env.REACT_APP_API_URL}/api/file-create`;
		let fd = new FormData();
       
          fd.append('file_name', image);
          fd.append('display_name', values.name);
          fd.append('project_id', project_id);
          fd.append('folder_id', values.folder_id);
          
          
          const data = Object.fromEntries(fd)
			axios.post(url, fd).then((ss) => {
		      meCloseEdit()  
	      	});
	}

	const handleChange = (e) => {
	    setImage(e.target.files[0]);
	  };

	  useEffect(() => {
        LoadfolderList(project_id)
    }, [project_id]);

  	const LoadfolderList = async (folder_id) => {
      const url = `${process.env.REACT_APP_API_URL}/api/folder-list?project_id=${project_id}`;
      const data  = await fetchWrapper.get(url)
      setFolderList(data.rows)
  	}

	return(
		<>

		<Form
			enableReinitialize
            validationSchema={FormSchema}
            initialValues={formData}
            onSubmit={onSubmit}
		>
			<SelectField name="folder_id" title="Folder Name" list={folderList}/>
			<div className="field_section">
			 		<input type="file" id="upload-button" onChange={handleChange} />
			 	</div>
			<InputText name="name" title="File Name" />

			<Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           	Save
		          		</Button>

		</Form>
		</>
	)
}

export default UploadFile;