import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,SelectField} from '../../../components/form'
import { fetchWrapper } from '../../../helpers';



function Create({loadList,meClose}){


	const [formData, setFormData] = useState({
   		month: "",
        year: "",
        code: "",
        rate: "",
        start: "",
        end: "",
        client_rate: "",

    
  });

  const FormSchema = Yup.object().shape({
	   month: Yup.string().required('Required'),
	   year: Yup.string().required('Required'),
	   code: Yup.string().required('Required'),
	   rate: Yup.string().required('Required'),
	   start: Yup.string().required('Required'),
	   end: Yup.string().required('Required'),
	   client_rate: Yup.string().required('Required'),

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  data.created_by = '0'
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/fl-code-create`;
  		 await fetchWrapper.post(url,data)
  		 loadList()
  		 meClose();
	}



	
	const Month = [
	  { id: '01', name: 'January' },
	  { id: '02', name: 'February' },
	  { id: '03', name: 'March' },
	  { id: '04', name: 'April' },
	  { id: '05', name: 'May' },
	  { id: '06', name: 'June' },
	  { id: '07', name: 'July' },
	  { id: '08', name: 'August' },
	  { id: '09', name: 'September' },
	  { id: '10', name: 'October' },
	  { id: '11', name: 'November' },
	  { id: '12', name: 'December' }
	];


	const currentYear = new Date().getFullYear();
const yearsBefore = Array.from({ length: 5 }, (_, i) => currentYear - 5 + i);
const yearsAfter = Array.from({ length: 5 }, (_, i) => currentYear + i + 1);

const Year = [...yearsBefore, currentYear, ...yearsAfter]


	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	
			 	<SelectField name="month" title="Month" list={Month}  />
			 	
			 	<SelectField name="year" title="Year" list={Year.map(year => ({ id: year.toString(), name: year.toString() }))}/>
			 	<InputText name="code" title="Code"  />
			 	<InputText name="rate" title="Rate"  />
			 	<InputText name="start" title="Start"  />
			 	<InputText name="end" title="End"  />
			 	<InputText name="client_rate" title="Client Rate"  />

		         <Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>

			</>

	)
}

export default Create;