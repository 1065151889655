import React,{useEffect,useState} from 'react';
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField} from '../../../components/form'
import { fetchWrapper } from '../../../helpers';

import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
} from '@chakra-ui/react'

function Create({loadList,meCloseEdit}) {


	const [formData, setFormData] = useState({
    	name:""
  	});

  	const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	      const url_id = window.location.href.split('/');
    		const id = url_id[url_id.length - 1];
    		data.project_id = id
	     const url = `${process.env.REACT_APP_API_URL}/api/folder-create`;
  		 await fetchWrapper.post(url,data)
  		 loadList(id)
  		 meCloseEdit();
	}
	return(
		<>

		<Form
			enableReinitialize
            validationSchema={FormSchema}
            initialValues={formData}
            onSubmit={onSubmit}
		>
			<InputText name="name" title="Name" />
			<Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           	Save
		          		</Button>

		</Form>
		</>
	)
}

export default Create;