import React,{useEffect,useState} from 'react';
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField} from '../../../components/form'
import { fetchWrapper } from '../../../helpers';

import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
} from '@chakra-ui/react'

function Create({loadList,meCloseEdit,rs}) {


	const [formData, setFormData] = useState({
    	total_budget: rs.total_budget === null ? '' : rs.total_budget,
  	});

  	const FormSchema = Yup.object().shape({
	   total_budget: Yup.string().required('Required')
    .matches(/^[^,]*$/, 'Only Number'),
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/sow-update/${rs.id}`;
  		 await fetchWrapper.put(url,data)
  		 loadList(rs.project_id)
  		 meCloseEdit();
	}
	return(
		<>

		<Form
			enableReinitialize
            validationSchema={FormSchema}
            initialValues={formData}
            onSubmit={onSubmit}
		>
			<InputText name="total_budget" title="Total Budget" />
			<Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           	{rs.total_budget === null || rs.total_budget === "" ? 'Add Budget' : "Edit Budget" }
		          		</Button>

		</Form>
		</>
	)
}

export default Create;