import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,SelectField} from '../../../components/form'
import { fetchWrapper } from '../../../helpers';



function Create({loadList,rs,meCloseEdit}){
	const [employee,setEmployee] = useState([])
	const [role,setRole] = useState([])

	const [formData, setFormData] = useState({
    name: rs.name,
    username:rs.username,
   
    employee_id:rs.employee_id,
    role_id:rs.role_id,
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   username: Yup.string().required('Required'),
	
	   employee_id: Yup.string().required('Required'),
	   role_id: Yup.string().required('Required'),

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		   data.email = data.username;	  
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/user-update/${rs.id}`;
  		 await fetchWrapper.put(url,data)
  		 loadList()
  		 meCloseEdit();
	}

	useEffect(() => {

        EmployeeList();
        RoleList()     
        
    }, []);

	 const EmployeeList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/cmd/all-employee`;
      const data  = await fetchWrapper.get(url)
      setEmployee(data.rows)
  	}
  	const RoleList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/role-list`;
      const data  = await fetchWrapper.get(url)
      setRole(data.rows)
  	}

	
// 	const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]

	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="name" title="Name"  />
			 	<InputText name="username" title="UserName"  />
			 
			 	<SelectField name="employee_id" title="Employee" list={employee}  />
			 	<SelectField name="role_id" title="Role" list={role}  />

		         <Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>

			</>

	)
}

export default Create;