import React,{useState,useEffect} from 'react';
import { HStack, Box, Image, Text } from '@chakra-ui/react';
import logo from '../../images/logo.png';
import * as Yup from 'yup';
import { Link ,useNavigate,useSearchParams} from "react-router-dom";
import {InputText,Form} from '../../components/form';


import {
  
  Button
} from '@chakra-ui/react';
import { fetchWrapper } from '../../helpers';

function Login(){
	const navigate  = useNavigate();


	let [searchParams, setSearchParams] = useSearchParams();

	const authorization_code = searchParams.get('authorization_code')

	if(!authorization_code){
		document.location.href = 'https://home.creativechaos.co'
	}

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    
    
  });
  const FormSchema = Yup.object().shape({
       email: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
       
  });

  useEffect(
        () => {
             loginSso()

        },	
        []
    );

  const loginSso = async() => {
  	const payLoad = {authorization_code:authorization_code}
              const url = `${process.env.REACT_APP_API_URL}/api/auth/validate-response`;
       				const loginData = await fetchWrapper.post(url,payLoad)
       				console.log(loginData)
       				const token = loginData.accesstoken
       		
       	localStorage.setItem("user",token);
      	 navigate("/dashboard");
       			//	console.log(loginData)
  }

  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

       let  data = values
      
         
       const url = `${process.env.REACT_APP_API_URL}/api/user-login-token`;
       const loginData = await fetchWrapper.post(url,data)
       
       .then(loginData =>{
       	const token = loginData.accesstoken
       	 localStorage.setItem("user",token);
        navigate("/settings");
       })
       .catch(err=>{
       	alert(err)
       })
       
        // console.log(loginData.accesstoken)
  }
	return(
		<>
			<HStack bg="#fff" h="100vh" justifyContent="center" flexDirection="column">
		      

		      <Box borderRadius="5px" width="481px" bg="#fff" py="30" px="25" borderWidth="1px" borderColor="#ccc" borderStyle="solid">
		      	<Box mb="30px">
			        <Image m="auto" src={logo} alt='Gun Guru' />
		      	</Box>

		      	<Text textAlign="center" color="#000" mb="20px" fontSize="16px" fontWeight="400" fontFamily="'Montserrat', sans-serif">Please login into your account</Text>
		      <Form
		      				enableReinitialize
                  validationSchema={FormSchema}
                  initialValues={formData}
                  onSubmit ={onSubmit}
		          
		       >
		           <InputText name="email" title="User Name"  />
		           <InputText inputProps={{type:'password'}} name="password" title="Password"  />


		           <Button type="submit" bg="#31367A" borderColor="#31367A" borderRadius="5px" fontSize="14px" fontWeight="500" color="#fff" width="100%" p="15" borderWidth="1px" borderStyle="solid">Login</Button>
		       </Form>
		        

		        
		      </Box>
		    </HStack>
		</>
	)
}

export default Login;