import logo from './logo.svg';
import './App.css';
import Login from './pages/auth/Login'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './components/Header'
import Settings from './pages/Settings'
import Project from './pages/Project'
import ProjectEdit from './pages/project/Edit.js'
import { PrivateRoute } from './components/PrivateRoute';
import Team from './pages/Team'
import Dashboard from './pages/Dashboard'
import Veiw from './pages/team/Veiw.js'

function App() {
  return (
    <>
      <ChakraProvider>
        <BrowserRouter>
          <Routes>  
            <Route path="/" element={<Login />} />

            <Route path="/login" element={<Login />} />
            
            <Route path="/settings" element=
              <PrivateRoute>
              {<Settings/>}
              </PrivateRoute>
            />

             <Route path="/dashboard" element=
              <PrivateRoute>
              {<Dashboard/>}
              </PrivateRoute>
            />

            <Route path="/project" element=
              <PrivateRoute>
              {<Project/>}
              </PrivateRoute>
            />
            <Route path="/edit/:id" element=
              <PrivateRoute>
              {<ProjectEdit/>}
              </PrivateRoute>
            />
            <Route path="/team" element=
              <PrivateRoute>
              {<Team/>}
              </PrivateRoute>
            />

            <Route path="/veiw/:id" element=
              <PrivateRoute>
              {<Veiw/>}
              </PrivateRoute>
            />

          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </>
  );
}

export default App;
