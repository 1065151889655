import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,SelectField} from '../../../components/form'
import { fetchWrapper } from '../../../helpers';


function Edit({rs,loadList,meCloseEdit}){
	const [country,setCountry] = useState([])

	const [formData, setFormData] = useState({
    name: rs.name,
    country_id:rs.country_id,
    id:rs.id
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   country_id: Yup.string().required('Required'),

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/city-update/${rs.id}`;
  		 await fetchWrapper.put(url,data)
  		 loadList()
  		 meCloseEdit();
	}
	useEffect(() => {
        countryList()        
    }, []);

	 const countryList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/country-list`;
      const data  = await fetchWrapper.get(url)
      setCountry(data.rows)
  	}

	
 	const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]

	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="name" title="City name"  />
			 	<SelectField name="country_id" title="Country" list={country}  />

		          <Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>

			</>

	)
}

export default Edit;