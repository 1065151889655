
import React,{useState,useEffect} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
   Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react'
import { fetchWrapper } from '../../helpers';
import emp_user from '../../images/Ellipse 9.png'

function  Team() {

	const [list,setList] = useState([])


	useEffect(() => {
        loadList()
    }, []);

  	const loadList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/cmd/all-employee`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows)
  	}


	return(
		<>
			<Box px="45px" py="20px">
				<Heading fontSize="32px" fontWeight="300" fontFamily="'Montserrat', sans-serif" color="#000">Employees</Heading>
			</Box>
		 <div className="page_section">	
			
			<Box display="flex" flexWrap="wrap" mb="20px">
			    {list.map((rs,index) => {
			        return(
			            <Link key={index} me="20px" mt="20px" w="30%"  cursor="pointer" href={`/veiw/${rs.id}`} style={{textDecoration :'none'}}>
			                <HStack border="1px solid #ccc" px="10px" py="20px" borderRadius="10px">
			                    <Box>
			                        <Image src={emp_user} alt="Employee User" />
			                    </Box>
			                    <Box marginLeft="10px">
			                        <Text fontWeight="400" fontSize="24px" color="#000000">
			                            {rs.name}
			                        </Text>
			                        <Text fontWeight="400" fontSize="12px" color="#00000080">
			                           {rs.designation}
			                        </Text>
			                        <Text fontWeight="400" fontSize="12px" color="#00000080">
			                            {rs.department}
			                        </Text>
			                    </Box>
			                </HStack>
			            </Link>
			        )
			    })}
			</Box>


			


		</div>

		</>
	)
}

export default Team