import React,{useEffect,useState} from 'react';
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField} from '../../../components/form'
import { fetchWrapper } from '../../../helpers';
import CustomModalLink from '../../../components/CustomModalLink'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

import CreateConsultant from './CreateConsultant'
import CreateResource from './CreateResource'

function List() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
  const [isOpenEdit,setIsOpenEdit] = useState(false)   
  const [modalEditView,setModalEditView] = useState(false)


  const [months, setMonths] = useState([]);
  const [selectedSow, setSelectedSow] = useState(null)  
  const [Sow, SetSow] = useState([])
  const [heading, setHeading] = useState([])
  const [Data, setData] = useState([])

  const [formData, setFormData] = useState({
    name: '',
  });

  // const Sow = [
  //   {id:'1',name:'Digital Connect - 1001'},
  //   {id:'2',name:'One Place Safe'}
  // ]

  const meCloseEdit = () => {
      
       setIsOpenEdit(false);
       
  }

  const meOpenModel = (veiw) => {
    setIsOpenEdit(true);
    if(veiw == 'consultant'){
      setModalEditView(<CreateConsultant meCloseEdit={meCloseEdit}/>)     
    }
    if(veiw == 'resource'){
      setModalEditView(<CreateResource meCloseEdit={meCloseEdit} SowId={selectedSow} loadList={loadList}/>)     
    }
    
  }

  useEffect(()=>{

    const url_id = window.location.href.split('/');
    const id = url_id[url_id.length - 1];
    LoadSowList(id)
    if (heading.start_date && heading.end_date) {
      const months = generateMonths();
      setMonths(months);
    }
  },[heading])

  const LoadSowList = async(id)=>{
    const url = `${process.env.REACT_APP_API_URL}/api/sow-list?project_id=${id}`;
      const data = await fetchWrapper.get(url)
      SetSow(data.rows)
  }

  const handleSelectChange = (value) => {
    setSelectedSow(value);
    if(value){
      loadHeader(value)
      loadList(value)
    }
    
    setFormData({ ...formData, name: value });
  };

  const loadHeader = async(value)=>{
    const url = `${process.env.REACT_APP_API_URL}/api/sow-list/${value}`;
      const data = await fetchWrapper.get(url)
      setHeading(data)
  }

  const monthMap = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};

const loadList = async(value) => {
  const url = `${process.env.REACT_APP_API_URL}/api/all-detail-list?sow_id=${value}`;
  const data = await fetchWrapper.get(url);
  setData(data?.rows);
  console.log(data?.rows);
}

const generateMonths = () => {
  const startMonth = new Date(heading.start_date).getMonth();
  const endMonth = new Date(heading.end_date).getMonth();
  const startYear = new Date(heading.start_date).getFullYear();
  const endYear = new Date(heading.end_date).getFullYear();
  
  const months = [];

  for (let year = startYear; year <= endYear; year++) {
    const start = year === startYear ? startMonth : 0;
    const end = year === endYear ? endMonth : 11;

    for (let month = start; month <= end; month++) {
      months.push({ month: month + 1, year });
    }
  }

  return months;
};

  return (
    <>
    <CustomModalLink
        showModalButtonText={`SOW: ${heading.name}`}
        modalHeader={`SOW: ${heading.name}`}
        isOpenEdit={isOpenEdit}
        onCloseEdit={meCloseEdit}
        onOpenEdit={onOpenEdit}
        
        modalBody={modalEditView}
      />
    <Form
                  enableReinitialize
                  // validationSchema={FormSchema}
                  initialValues={formData}
                  // onSubmit={onSubmit}
       >
       <HStack justifyContent="space-between">
          <Box w="50%">
            <SelectField name="name" title="SOW" list={Sow} onChange={handleSelectChange}/>
          </Box>
           {selectedSow && ( 
              <HStack justifyContent="end">
                <Box color="#fff" fontSize="14px" fontWeight="400">
                  <div onClick={() => meOpenModel('consultant')} id="button1">Add a Consultant</div>
                </Box>
                <Box color="#fff" fontSize="14px" fontWeight="400">
                  <div onClick={() => meOpenModel('resource')} id="button2">Allocate a Resource</div>
                </Box>
              </HStack>
            )}
       </HStack>
      

       {/*Box Area */}

       <HStack mb="20px">
          

          <Box borderWidth="1px" borderColor="#ccc" borderRadius="5px" w="100%" me="15px" h="115px" bg="#288BFF">
            <HStack justifyContent="space-between" >
              <Box py="10px" px="15px" >
              <Text fontSize="32px" fontWeight="600" color="#FFFFFF" mb="0" >Rs. 10,000,000</Text>
              <Text color="#FFFFFF" fontWeight="400" fontSize="14px">Total Budget</Text>
            </Box>
            </HStack>
          </Box>

          <Box borderWidth="1px" borderColor="#ccc" w="100%" borderRadius="5px" me="15px" h="115px" bg="#FB5656">
            <HStack justifyContent="space-between">
              <Box py="10px" px="15px" >
              <Text fontSize="32px" fontWeight="600" color="#FFFFFF" mb="0" >Rs. 9,000,000</Text>
              <Text color="#FFFFFF" fontWeight="400" fontSize="14px">Budget Utilized</Text>
            </Box>
            
            </HStack>
          </Box>

          <Box borderWidth="1px" borderColor="#ccc" w="100%" borderRadius="5px" me="15px" h="115px" bg="#5ED84A">
            <HStack justifyContent="space-between">
              <Box py="10px" px="15px" >
              <Text fontSize="32px" fontWeight="600" color="#FFFFFF" mb="0" >Rs. 100,000</Text>
              <Text color="#FFFFFF" fontWeight="400" fontSize="14px">Balance</Text>
            </Box>
            
            </HStack>
          </Box>

          <Box borderWidth="1px" borderColor="#ccc" w="100%" borderRadius="5px" me="15px" h="115px" bg="#31367A">
            <HStack justifyContent="space-between">
              <Box py="10px" px="15px" >
              <Text fontSize="32px" fontWeight="600" color="#FFFFFF" mb="0" >10</Text>
              <Text color="#FFFFFF" fontWeight="400" fontSize="14px">Active Team Members</Text>
            </Box>
            
            </HStack>
          </Box>
        </HStack> 

      {/* Table Area */}  
        {selectedSow && months.map((month, index) => (
          <div key={index}>
            <HStack justifyContent="space-between" borderWidth="1px" borderColor="#ccc" bg="#CCCCCC" h="40px" px="20px">
              <Box>
                <Text fontSize="14px" fontWeight="600" color="#000000">{monthMap[month.month]} {month.year}</Text>
              </Box>
              <Box>
                <Text fontSize="14px" fontWeight="600" color="#000000">Month’s Budget: Rs 1,500,000</Text>
              </Box>
            </HStack>
            
            <div className="table_section" style={{ marginBottom: '30px' }}>
              <TableContainer width="100%">
                <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">

                  <Thead>
                    <Tr>
                      <Th>S Code</Th>
                      <Th>Name</Th>
                      <Th>Role in Project</Th>
                      <Th>Daily Allocated Hours</Th>
                      <Th>Allocated Hours</Th>
                      <Th>Days Off</Th>
                      <Th>Holidays</Th>
                      <Th>Total Cost</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                 
                  <Tbody>
                   
                    {Data.filter(row => row.month === month.month && row.year === month.year).length === 0 ? (
                      <Tr>
                        <Td colSpan="9">No data Found </Td>
                      </Tr>
                    ) : (
                      Data.filter(row => row.month === month.month && row.year === month.year).map((rs, rowIndex) => (
                        <Tr key={rowIndex}>
                          <Td>1</Td>
                          <Td w="20%">
                            <Text fontWeight="400" fontSize="14px">{rs?.Employee?.name}</Text>
                            <Text fontWeight="400" fontSize="12px">Sr. Software Engineer</Text>
                          </Td>
                          <Td>Backend Developer</Td>
                          <Td>{rs?.hour_per_day} Hours</Td>
                          <Td>160 Hours</Td>
                          <Td>
                            <Text fontWeight="400" fontSize="14px">2 Days</Text>
                            <Text fontWeight="400" fontSize="12px">16 Hours</Text>
                          </Td>
                          <Td>
                            <Text fontWeight="400" fontSize="14px">2 Days</Text>
                            <Text fontWeight="400" fontSize="12px">16 Hours</Text>
                          </Td>
                          <Td>Rs 300,000</Td>
                          <Td style={{ color: "#0085FF" }}>
                            <Menu>
                              <MenuButton fontSize="14px">
                                Action
                                <ChevronDownIcon />
                              </MenuButton>
                              <MenuList style={{ minWidth: "20px", border: "1px solid #CCCCCC" }}>
                                <MenuItem h="16px" fontSize="12px">Edit</MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      ))
                    )}

                  </Tbody>
                 
                  <Tfoot bg="#CCCCCC">
                    <Tr>
                      <Th>Total</Th>
                      <Th>5 Resources</Th>
                      <Th></Th>
                      <Th>15 Hours</Th>
                      <Th>320 Hours</Th>
                      <Th>4 Days</Th>
                      <Th>5 Days</Th>
                      <Th>Rs 600,000</Th>
                      <Th></Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </div>
          </div>
        ))}    


        
      </Form>                  
    </>  
  );
}

export default List;