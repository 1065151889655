import React,{useEffect,useState} from 'react';
import { HStack, Box, Image, Text, Button } from '@chakra-ui/react';
import {InputText,Form,SelectField} from '../../components/form'
import * as Yup from 'yup';
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react'
import { Link } from "react-router-dom";

import { fetchWrapper } from '../../helpers';

function InnerNav({linked,loadPage}){

  const [addrole, setAddRole] = useState(false);
	const [selectedLink, setSelectedLink] = useState('');
  const [list ,setList] = useState([]);

useEffect(() => {
  // Set the initially selected link based on the linked prop
  switch (linked) {
    case "Roles":
      handleLinkClick("AllUser");
      setSelectedLink("AllUser");
      break;
    case "Localization":
      handleLinkClick("country");
      setSelectedLink("country");
      break;
    case "Timesheets":
      handleLinkClick("task");
      setSelectedLink("task");
      break;
    case "Employees":
      handleLinkClick("fl_code");
      setSelectedLink("fl_code");
      break;
    case "Projects":
      handleLinkClick("project_type");
      setSelectedLink("project_type");
      break;
    default:
      setSelectedLink(linked);
      break;
  }
}, [linked]);



  const handleLinkClick = (link) => {
    setSelectedLink(link);
    // You can perform additional actions here, such as loading a specific page
    loadPage(link);
  };

  const [formData, setFormData] = useState({
    name: "",    
  });

  const FormSchema = Yup.object().shape({
     name: Yup.string().required('Required'),    

  });


  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

       let  data = values
      
         
       const url = `${process.env.REACT_APP_API_URL}/api/role-create`
       await fetchWrapper.post(url,data)
       loadList()
       setAddRole(false)
  }

  const AddRole =  ()=>{
    setAddRole(true)
  }

  useEffect(()=>{
    loadList();
  },[])

  const loadList = async()=>{
    const url = `${process.env.REACT_APP_API_URL}/api/role-list`
    const data = await fetchWrapper.get(url);
    setList(data.rows)
  }

  const handlechange =(rs)=>{
    handleLinkClick(rs.name)
    loadPage("AllUser",rs)
    // console.log(rs.id)
  }



	return(
		<>
			<Box height="100vh" borderRightWidth="1px" borderStyle="solid" borderColor="#ccc" py="20px">
				<UnorderedList m="0px" p="0px" listStyleType="none">

				 {linked === "Localization" && (
            <>
              <ListItem>
                <Link
                  className={`setting_nav_link ${selectedLink === "country" ? "active" : ""}`}
                  onClick={() => handleLinkClick("country")}
                >
                  Country
                </Link>
              </ListItem>
              <ListItem mt="10px">
                <Link
                  className={`setting_nav_link ${selectedLink === "city" ? "active" : ""}`}
                  onClick={() => handleLinkClick("city")}
                >
                  Cities
                </Link>
              </ListItem>
              <ListItem mt="10px">
                <Link
                  className={`setting_nav_link ${selectedLink === "currency" ? "active" : ""}`}
                  onClick={() => handleLinkClick("currency")}
                >
                  Currency
                </Link>
              </ListItem>
            </>
          )}

          {linked === "Projects" && (
            <>
              <ListItem>
                <Link
                  className={`setting_nav_link ${selectedLink === "project_type" ? "active" : ""}`}
                  onClick={() => handleLinkClick("project_type")}
                >
                  Project Type
                </Link>
              </ListItem>

              <ListItem mt="10px">
                <Link
                  className={`setting_nav_link ${selectedLink === "team" ? "active" : ""}`}
                  onClick={() => handleLinkClick("team")}
                >
                  Team
                </Link>
              </ListItem>

              <ListItem mt="10px">
                <Link
                  className={`setting_nav_link ${selectedLink === "business" ? "active" : ""}`}
                  onClick={() => handleLinkClick("business")}
                >
                  Business Unit
                </Link>
              </ListItem>

              <ListItem mt="10px">
                <Link
                  className={`setting_nav_link ${selectedLink === "project_manage" ? "active" : ""}`}
                  onClick={() => handleLinkClick("project_manage")}
                >
                  Project Manager
                </Link>
              </ListItem>

              <ListItem mt="10px">
                <Link
                  className={`setting_nav_link ${selectedLink === "client" ? "active" : ""}`}
                  onClick={() => handleLinkClick("client")}
                >
                  Client
                </Link>
              </ListItem>

              <ListItem mt="10px">
                <Link
                  className={`setting_nav_link ${selectedLink === "jobrole" ? "active" : ""}`}
                  onClick={() => handleLinkClick("jobrole")}
                >
                  Job Role
                </Link>
              </ListItem>
              
              
            </>
          )}



          {linked === "Timesheets" && (
            <>
              <ListItem>
                <Link
                  className={`setting_nav_link ${selectedLink === "task" ? "active" : ""}`}
                  onClick={() => handleLinkClick("task")}
                >
                  Task
                </Link>
              </ListItem>
              
              
            </>
          )}

          {linked === "Employees" && (
            <>
              <ListItem>
                <Link
                  className={`setting_nav_link ${selectedLink === "fl_code" ? "active" : ""}`}
                  onClick={() => handleLinkClick("fl_code")}
                >
                  FL_Code
                </Link>
              </ListItem>
              
              
            </>
          )}


        {addrole  && linked === "Roles" && (
          <Form
                  enableReinitialize
                  validationSchema={FormSchema}
                  initialValues={formData}
                  onSubmit={onSubmit}
         >


          <InputText name="name" title="Role name" w="0px"  />
                <Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif"
                      mb={4}
                      
                      type='submit'
                    >
                     Save
                    </Button>
                <Button ml="2px" py="10px" px="25px" color="black" bg="rgba(49, 54, 122, 0.2)" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif"
                      mb={4} onClick={()=>  setAddRole(false)}
                    >
                     Cancel
                    </Button>    

          </Form>
        )} 


          {linked === "Roles" && (
            <>
            {addrole === false ? (
              <Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif"
              mb={4} onClick={AddRole} >Add Roles</Button>
            ):null}

              <ListItem>
                <Link
                  className={`setting_nav_link ${selectedLink === "AllUser" ? "active" : ""}`}
                  onClick={() => handleLinkClick("AllUser")}
                >
                  All User
                </Link>
              </ListItem>
              
              {list.map((rs, index) => (
               <ListItem mt="3px">
                <Link
                  className={`setting_nav_link ${selectedLink === `${rs.name}` ? "active" : ""}`}
                  onClick={() => handlechange(rs)}
                >
                  {rs.name}
                </Link>
              </ListItem>
              ))}
            </>
          )}


				</UnorderedList>
			</Box>
		</>
	);
}

export default InnerNav;