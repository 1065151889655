import React,{useState,useEffect} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
   Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { fetchWrapper } from '../../../helpers';
import { ChevronDownIcon } from '@chakra-ui/icons'

function FileList({folder_id,show}) {

	const [list,setList] = useState([])


	useEffect(() => {
        loadList(folder_id)
    }, [folder_id,show]);

  	const loadList = async (folder_id) => {
      const url = `${process.env.REACT_APP_API_URL}/api/file-list?folder_id=${folder_id}`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows)
  	}
	return(
		<>

		<div className="table_section">
				<TableContainer width="100%">
			  		<Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
					    <Thead>
						      <Tr>
						        <Th>File Name</Th>
					         	<Th>Uploaded By</Th>
				          		<Th>Date</Th>
				          		<Th></Th>		         
						      </Tr>
					    </Thead>
					    <Tbody>	
						  		{list.map((rs,index)=>{
									return(
										<>
											<Tr>
							                    <Td>{rs.display_name}</Td>
							                    <Td>{rs.created_by}</Td>
							                    <Td>{rs.createdAt}</Td>	
							                    <Td style={{color:"#0085FF"}}>
							                    	<Menu>
						                              <MenuButton fontSize="14px">
						                              Action
						                                
						                                  <ChevronDownIcon />
						                              </MenuButton>

						                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
						                                <MenuItem  h="16px" fontSize="12px">Edit</MenuItem>
						                                	<MenuDivider />
						                                <MenuItem h="16px" fontSize="12px">Delete</MenuItem>
						                                
						                                
						                              </MenuList>
						                            </Menu>
							                    </Td>		                            
					                    	</Tr>
										</>
									)
								})}
			            	
			                  
			                    
			          
			       		        
					    </Tbody> 
				  	</Table>
				</TableContainer>
				</div>
		</>
	)
}
export default FileList