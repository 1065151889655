import React,{useEffect,useState} from 'react';
import CustomModal from '../../../components/CustomModal'
import CustomModalLink from '../../../components/CustomModalLink'
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Link,
  useDisclosure,
} from '@chakra-ui/react'
import Create from './Create'
import Edit from './Edit'
import { fetchWrapper } from '../../../helpers';

function List({loaddata}){
	const role_id = loaddata?.id
	// console.log(role_id)
	const { isOpen, onOpen, onClose } = useDisclosure();
	
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)

  const [list,setList] = useState([])

  	useEffect(() => {

        loadList(role_id)   

    }, [role_id]);

  const loadList = async (role_id="") => {
      const url = `${process.env.REACT_APP_API_URL}/api/user-list?role_id=${role_id}`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows)
  }

  const meClose = () => {
  		
		   onClose();
		   
	}
	
	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}

	const meEdit = (rs) => {
		setIsOpenEdit(true);
		setModalEditView(<Edit rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)		
	}

	// const meDelete = async (rs) => {
  //     const aa = window.confirm("Are you sure delete this record");
  //     if(aa){
        
  //       const url = `${process.env.REACT_APP_API_URL}/api/user-delete/${rs.id}`;
  //       await fetchWrapper.delete(url) 
  //       loadList() 
  //     }
  // }

  return(
    <>
    	<CustomModalLink
        showModalButtonText="Edit User"
        modalHeader="Edit User"
     		isOpenEdit={isOpenEdit}
     		onCloseEdit={meCloseEdit}
     		onOpenEdit={onOpenEdit}
        
        modalBody={modalEditView}
    	/>
    	
      <div className="setting_page_section">
				<HStack justifyContent="space-between" mb="25px">
					<Box>
						<Text fontSize="16px" fontWeight="700" color="#000" my="0px" fontFamily="'Montserrat', sans-serif">User</Text>
					</Box>

					<Box>
						<CustomModal
              showModalButtonText="Add New User"
              modalHeader="Create User"
              isOpen={isOpen}
           		onClose={onClose}
           		onOpen={onOpen}
              modalBody=<Create loadList={loadList} meClose={meClose} />
            />
					</Box>		
				</HStack>

				<div className="table_section">
					<TableContainer width="100%">
					  <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
					    <Thead>
					      <Tr>
					        <Th>Name</Th>
					        <Th>Login Id</Th>
				         	<Th>Employee</Th>
				          <Th>Role</Th>
					        <Th></Th>
					      </Tr>
					    </Thead>
					    <Tbody>
					    {list.map((rs,index)=>{
					    	return(
					    	<>
						    	<Tr key={index}>
						      	<Td>{rs.name}</Td>
						      	<Td>{rs.email}</Td>
						      	<Td>{rs?.Employee?.name}</Td>
						      	<Td>{rs?.Role?.name}</Td>
						      
						      	<Td cursor="pointer">
						      		<HStack mb="5px" mr="65px" justifyContent="right" >
	                      <a onClick={()=> meEdit(rs)}>
	                        <BsPencil />
	                      </a>  
	                      {/*<a>
	                        
	                        <BsFillTrashFill />
	                      </a>*/}
                      </HStack>
						      	</Td>
						      </Tr>
					    	</>
					    	)
					    })}					    
					      
					    </Tbody>
					    
					  </Table>
					</TableContainer>
				</div>
			</div>

      
		</>
    )
}

export default List;