import React, { useEffect, useState } from 'react';
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import * as Yup from 'yup';
import { InputText, Form, InputDateField, SelectField } from '../../../components/form'
import { fetchWrapper } from '../../../helpers';

import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator,
  Button,
  Link,
  useDisclosure,
} from '@chakra-ui/react'

function Create({ loadList, meCloseEdit, rs }) {
  const [formData, setFormData] = useState({});
  const [monthList, setMonthList] = useState([]);

  // Format date to MM YYYY
  const formatDate = (date) => {
    const options = { month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    // Initialize formData with default values
    const initialFormData = {};
    for (const month of monthList) {
      initialFormData[formatDate(month)] = '';
    }
    setFormData(initialFormData);
  }, [rs, monthList]);

  useEffect(() => {
    // Generate month list when rs changes
    const startDate = new Date(rs.start_date);
    const endDate = new Date(rs.end_date);
    const months = generateMonthList(startDate, endDate);
    setMonthList(months);
  }, [rs]);

  const generateMonthList = (startDate, endDate) => {
    const months = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      months.push(new Date(currentDate));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return months;
  };

  const FormSchema = Yup.object().shape({
    // Define validation schema for each month
    ...Object.fromEntries(monthList.map(month => [formatDate(month), Yup.string().required('Required').matches(/^[^,]*$/, 'Only Number'),]))
  });

  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
    let data = values;

    const requestBody = {
      sow_id: rs.id,
      budget_details: values
    };
    let totalBudget = 0;
    for (const month of monthList) {
      totalBudget += parseInt(data[formatDate(month)] || 0);
    }
    if(totalBudget === rs?.total_budget){
      const url = `${process.env.REACT_APP_API_URL}/api/budget-create`;
      fetchWrapper.post(url,requestBody)
      meCloseEdit();
    }else{
      alert(`Your Total Budget is ${parseInt(rs?.total_budget).toLocaleString()}`)
    }
    
  }

  return (
    <>
      <Form
        enableReinitialize
        validationSchema={FormSchema}
        initialValues={formData}
        onSubmit={onSubmit}
      >
        <Box>

          <hr style={{ borderBottom: '1px solid #CCCCCC' }} />
          <Box>
            <HStack display="flex" px="5px" py="20px">
              <Box fontSize="12px" fontWeight="700" color="#000000">Total Budget:</Box>
              <Box fontSize="12px" fontWeight="400" color="#000000">Rs {rs?.total_budget ? parseInt(rs?.total_budget).toLocaleString() : ''}</Box>
            </HStack>
          </Box>

          <div className="table_section budget_table">
            <TableContainer width="100%">
              <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
                <Thead>
                  <Tr>
                    <Th width="50%">Month</Th>
                    <Th>Budget</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {monthList.map((month, index) => (
                    <Tr key={index}>
                      <Td>{formatDate(month)}</Td>
                      <Td><InputText name={formatDate(month)} title="" /></Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
          <Button
            py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" mt="10px" fontWeight="700" fontFamily="'Open Sans', sans-serif"
            mb={4}
            type='submit'
          >
            Add Budget
          </Button>

        </Box>
      </Form>
    </>
  )
}

export default Create;
