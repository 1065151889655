
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
const CustomModal = ({ showModalButtonText, modalHeader, modalBody,isOpen, onOpen, onClose }) => {
  

 
  return (
    <>
      <Button bg="#31367A" padding="20px" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif" 
      color="#fff" onClick={onOpen}>
        {showModalButtonText}
      </Button>
      <Modal isOpen={isOpen} size='xl' onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            
            {modalBody}
            
          </ModalBody>

          
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomModal;