import React,{useState,useEffect} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
   Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import folder from '../../../images/folder.png'
import folderOpen from '../../../images/open_folder.png'
import CustomModalLink from '../../../components/CustomModalLink'
import Create_Folder from './Create_Folder'
import { fetchWrapper } from '../../../helpers';
import UploadFile from './Upload_File'
import FileList from './File_List'

import { ChevronDownIcon } from '@chakra-ui/icons'

function Files() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [id, setId] = useState(null)
	const [ModelHeader, SetModelHeader] = useState('')
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	 const [openAccordion, setOpenAccordion] = useState(null);

	 const [list,setList] = useState([])
	const [show,setShow] = useState(false)
  const handleAccordionClick = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const meClose = () => {
  		
		   onClose();
		   
	}

	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   setShow(!show)
		   
	}

	const meOpenModel = (veiw ) => {
		if(veiw === 'folder-create'){
			SetModelHeader('Create Folder')
			setIsOpenEdit(true);
			setModalEditView(<Create_Folder meCloseEdit={meCloseEdit} loadList={loadList}/>)
		}
		if(veiw === 'upload-file'){
			SetModelHeader('Upload Files')
			setIsOpenEdit(true);
			setModalEditView(<UploadFile meCloseEdit={meCloseEdit}  project_id={id}/>)
		}
				
	}


	useEffect(() => {
		const url_id = window.location.href.split('/');
        const id = url_id[url_id.length - 1];
        setId(id);
        loadList(id)
    }, []);

  	const loadList = async (id) => {
      const url = `${process.env.REACT_APP_API_URL}/api/folder-list?project_id=${id}`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows)
  	}

	return(
		<>

		<CustomModalLink
        showModalButtonText={ModelHeader}
        modalHeader={ModelHeader}
        isOpenEdit={isOpenEdit}
        onCloseEdit={meCloseEdit}
        onOpenEdit={onOpenEdit}
        
        modalBody={modalEditView}
      />

		<HStack justifyContent="space-between" mb="10px">
          <Box w="50%">
            
          </Box>
           
              <HStack justifyContent="end">
                <Box color="#fff" fontSize="14px" fontWeight="400">
                  <div onClick={() => meOpenModel('folder-create')} id="button1">Create Folder</div>
                </Box>
               	{list.length > 0 && (
		          <Box color="#fff" fontSize="14px" fontWeight="400">
		            <div onClick={() => meOpenModel('upload-file')} id="button2">Upload File</div>
		          </Box>
		        )}

              </HStack>

       </HStack>

       {list.map((rs,index)=>{
       	return(
       	<>
       	<Accordion allowMultiple className="accordion_section">
	      <AccordionItem mb="20px" borderWidth="1px" borderColor="#ccc">
	        <h2>
	          <AccordionButton onClick={()=> handleAccordionClick(index)}>
	         
	            <Box as='span' flex='1' textAlign='left'>
		            <HStack>
			            <Box h="40px" me="10px">
			             <Image src={openAccordion === index ? folderOpen : folder}/>
			            </Box>
			            <Box>
			            	<Text fontWeight="700" fontSize="12px">{rs.name}</Text>
			            </Box>
		            </HStack>
	            </Box>
	            <Box style={{color:"#0085FF"}} onClick={(e) => e.stopPropagation()}>
			            
                            <Menu>
                              <MenuButton fontSize="14px" >
                              Action
                                
                                  <ChevronDownIcon />
                              </MenuButton>

                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
                                <MenuItem  h="16px" fontSize="12px">Edit</MenuItem>
                                	<MenuDivider />
                                <MenuItem h="16px" fontSize="12px">Delete</MenuItem>
                                
                                
                              </MenuList>
                            </Menu>
                            
			            </Box>
	          
	          </AccordionButton>
	        </h2>
	        <AccordionPanel p="0px" isOpen={openAccordion === index}>
	          <FileList  show={show} folder_id={rs.id}/>
	        </AccordionPanel>
	      </AccordionItem>

      
    </Accordion>
       	</>
       	)
       })}


		
		</>


	)
}

export default Files;