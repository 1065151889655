import React,{useEffect,useState} from 'react';
import { fetchWrapper } from '../../../helpers';
import Create from './Create'
import CreateBudget from './Create-Budget'

import CustomModalLink from '../../../components/CustomModalLink'
import CustomModal from '../../../components/CustomModal'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

function List() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [list,setList] = useState([])

	const [Name,setName] = useState('')

	useEffect(() => {
				const url_id = window.location.href.split('/');
        const id = url_id[url_id.length - 1];

        loadList(id)
    }, []);

  	const loadList = async (id) => {
      const url = `${process.env.REACT_APP_API_URL}/api/sow-list?project_id=${id}`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows)
  	}

  	const meClose = () => {
  		
		   onClose();
		   
	}

	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}

	const meEdit = (veiw , rs) => {
		const Name = rs.name
			setName(Name)
		if(veiw === 'Edit'){
			setIsOpenEdit(true);
			setModalEditView(<Create rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)
		}
		if(veiw === 'mb'){
			setIsOpenEdit(true);
			setModalEditView(<CreateBudget rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)
		}
				
	}

	return(
		<>
		
			<CustomModalLink
        showModalButtonText="Add Budget"
        modalHeader={Name}
     		isOpenEdit={isOpenEdit}
     		onCloseEdit={meCloseEdit}
     		onOpenEdit={onOpenEdit}
        modalBody={modalEditView}
    	/>
		<HStack justifyContent="space-between" mb="10px">
			<Box>
			<Text>Active</Text>
			</Box>

				
		</HStack>
		<div className="table_section">
			<TableContainer width="100%">
		  		<Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
				    <Thead>
					      <Tr>
					        <Th>SOW Name</Th>
				         	<Th>Start Date</Th>
			          	<Th>End Date</Th>
			          	<Th>No of Resources</Th>
					        <Th>Total SOW Hours</Th>
					        <Th>Total budgets</Th>
					        <Th>Monthly budgets</Th>
					        <Th>Status</Th>
					       
					        <Th></Th>
					      </Tr>
				    </Thead>
				    <Tbody>	
					    {list.map((rs,index)=>{
		            	return(
		                   <Tr key={index}>
			                    <Td style={{color:"#3B86FF"}}>{rs.name}</Td>
			                    <Td>{rs.start_date}</Td>
			                    <Td>{rs.end_date}</Td>
			                   	<Td>2</Td>
			                    <Td>2</Td>
			                    <Td>{rs.total_budget ? parseInt(rs.total_budget).toLocaleString() : ''}</Td>
			                    <Td>{rs.total_budget ? parseInt(rs.total_budget).toLocaleString() : ''}</Td>
			                    <Td>InProcess</Td>                   
			                    <Td style={{color:"#0085FF"}}>
                            <Menu>
                              <MenuButton fontSize="14px" >
                              Action
                                
                                  <ChevronDownIcon />
                              </MenuButton>

                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
                                <MenuItem  h="16px" fontSize="12px" onClick={() => meEdit('Edit',rs)}>{rs.total_budget === null || rs.total_budget === '' ? 'Add Budget' : 'Edit Budget'}</MenuItem>
                                <MenuDivider />
                                {rs.total_budget ? 
                                	<MenuItem h="16px" fontSize="12px" onClick={() => meEdit('mb',rs)} >Monthly Budget</MenuItem>
                                : 
                                <MenuItem style={{ color: 'red' }} h="16px" fontSize="12px">No Budget</MenuItem>
                              	}
                                
                                
                              </MenuList>
                            </Menu>
                            </Td>
		                    </Tr>
		              	)
		          		})}				        
				    </Tbody> 
			  	</Table>
			</TableContainer>
				</div>
		</>
	)
}

export default List;