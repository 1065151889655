import React,{useEffect,useState} from 'react';
import { fetchWrapper } from '../../../helpers';
import Create from './Create'
import CustomerModalLink from '../../../components/CustomerModalLink'
import CustomModalLink from '../../../components/CustomModalLink'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

function List() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [list,setList] = useState([])

	useEffect(() => {
		const url_id = window.location.href.split('/');
  		const id = url_id[url_id.length - 1];
        loadList(id)
    }, []);

  	const loadList = async (id) => {
      const url = `${process.env.REACT_APP_API_URL}/api/sow-list?project_id=${id}`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows)
  	}

  	const meClose = () => {
  		
		   onClose();
		   
	}

	return(
		<>
		<HStack justifyContent="space-between" mb="10px">
			<Box>
			<Text>Active</Text>
			</Box>

			<Box>
				<CustomerModalLink
		          	showModalButtonText="Add New SOW"
		         	modalHeader="Add New SOW"
		          	isOpen={isOpen}
		       		onClose={onClose}
		       		onOpen={onOpen}
		            modalBody=<Create meClose={meClose} loadList={loadList} />
				/>
			</Box>		
		</HStack>
		<div className="table_section">
			<TableContainer width="100%">
		  		<Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
				    <Thead>
					      <Tr>
					        <Th>SOW Name</Th>
				         	<Th>Start Date</Th>
				          	<Th>End Date</Th>
				          	<Th>No of Resources</Th>
					        <Th>Total SOW Hours</Th>
					        <Th>Status</Th>
					       
					        <Th></Th>
					      </Tr>
				    </Thead>
				    <Tbody>	
					    {list.map((rs,index)=>{
		            	return(
		                   <Tr key={index}>
			                    <Td style={{color:"#3B86FF"}}>{rs.name}</Td>
			                    <Td>{rs.start_date}</Td>
			                    <Td>{rs.end_date}</Td>
			                   	<Td>2</Td>
			                    <Td>2</Td>
			                    <Td>InProcess</Td>

			                    <Td style={{color:"#0085FF"}}>
                            <Menu>
                              <MenuButton fontSize="14px" >
                              Action
                                
                                  <ChevronDownIcon />
                              </MenuButton>

                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
                                <MenuItem  h="16px" fontSize="12px" >Edit</MenuItem>
                                <MenuDivider />
                                <MenuItem h="16px" fontSize="12px">Delete</MenuItem>
                                
                              </MenuList>
                            </Menu>
                            </Td>
			                    
		                    </Tr>
		              	)
		          		})}				        
				    </Tbody> 
			  	</Table>
			</TableContainer>
				</div>
		</>
	)
}

export default List;