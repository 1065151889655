import React,{useEffect,useState} from 'react';
import { HStack, Box, Image, Text, Button, Heading } from '@chakra-ui/react';
import LeftNav from './settings/LeftNav';
import InnerNav from './settings/InnerNav';
import Header from '../components/Header';
import Country from './settings/Country';
import City from './settings/City';
import Currency from './settings/Currency';
import User from './settings/User';
import ProjectType from './settings/ProjectType';
import Team from './settings/Team';
import Client from './settings/Client';
import ProjectManage from './settings/ProjectManage';
import Business from './settings/Business';
import JobRole from './settings/JobRole';
import FL_Code from './settings/FL_Code';

function Settings(){
	 const [ getPage, setGetPage ] = useState(false);


	
	 useEffect(() => {
        loadPage('country')
       
       
        
    }, []);

	const loadPage = (page_id,rs) => {
		
		if(page_id == 'country'){
			setGetPage(<Country />)
		}
		if (page_id == "city") {
  			setGetPage(<City />)
		}
		if (page_id == "currency") {
  			setGetPage(<Currency />)
		}
		if (page_id == "task") {
  			setGetPage(<h1>Task Page</h1>)
		}

		if (page_id == "AllUser") {
  			setGetPage(<User rs={rs}/>)
		}

		if (page_id == "project_type") {
  			setGetPage(<ProjectType/>)
		}
		if (page_id == "team") {
  			setGetPage(<Team/>)
		}
		if (page_id == "business") {
  			setGetPage(<Business/>)
		}
		if (page_id == "project_manage") {
  			setGetPage(<ProjectManage/>)
		}
		if (page_id == "client") {
  			setGetPage(<Client/>)
		}
		if (page_id == "jobrole") {
  			setGetPage(<JobRole/>)
		}
		if (page_id == "fl_code") {
  			setGetPage(<FL_Code/>)
		}


		
	} 

	return(
		<>
		<Header />

		<Box px="50px" py="20px">
			<Heading fontSize="32px" fontWeight="300" fontFamily="'Montserrat', sans-serif" color="#000">Settings</Heading>
		</Box>

		<div className="setting_page">
			<Box>
				<HStack alignItems="top" gap="0">
					<Box w="36%">
						<LeftNav loadPage={loadPage} />
					</Box>

					{/*<Box w="19%">
						<InnerNav loadPage={loadPage} />
					</Box>*/}

					<Box w="64%" height="100vh" overflowY="scroll">
						{getPage}
					</Box>
				</HStack>
			</Box>
		</div>				
		</>
	);
}

export default Settings;