import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,SelectField,TextAreaField} from '../../components/form'
import { fetchWrapper } from '../../helpers';



function Create({loadList,meClose}){

	const [projecttype,setProjectType]= useState([]);
	const [team,setTeam] = useState([]);
	const [business,setBusinessUnit]= useState([]);
	const [projectmanage,setProjectManage]= useState([]);
	const [client,setClient]= useState([]);


	const [formData, setFormData] = useState({
    name: '',
    project_overview: '',
    project_type_id: '',
    team_id: '',
    business_unit_id: '',
    project_manage_id: '',
    client_id: '',

    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   project_overview: Yup.string().required('Required'),
	   project_type_id: Yup.string().required('Required'),
	   team_id: Yup.string().required('Required'),
	   business_unit_id: Yup.string().required('Required'),
	   project_manage_id: Yup.string().required('Required'),
	   client_id: Yup.string().required('Required'),

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		   
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/project-create`;
  		 await fetchWrapper.post(url,data)
  		 loadList()
  		 meClose();
	}


	useEffect(()=>{

		ProjectTypeList()
		TeamList()
		BusinessUnitList()
		ProjectManageList()
		ClientList()
	},[])

	const ProjectTypeList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/api/project-type-list`;
  		const data = await fetchWrapper.get(url)
  		setProjectType(data.rows)
	}
	const TeamList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/api/team-list`;
  		const data = await fetchWrapper.get(url)
  		setTeam(data.rows)
	}

	const BusinessUnitList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/api/business-list`;
  		const data = await fetchWrapper.get(url)
  		setBusinessUnit(data.rows)
	}

	const ProjectManageList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/api/project-manage-list`;
  		const data = await fetchWrapper.get(url)
  		setProjectManage(data.rows)
	}


	const ClientList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/api/client-list`;
  		const data = await fetchWrapper.get(url)
  		setClient(data.rows)
	}

	
	// const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]

	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="name" title="Project Name"  />
			 	<TextAreaField name="project_overview" title="Project Overview"  />

			 	<SelectField name="project_type_id" list={projecttype}  title="Project Type"/>
			 	<SelectField name="team_id" list={team}  title="Team"/>
			 	<SelectField name="business_unit_id" list={business}  title="Business Unit"/>
			 	<SelectField name="project_manage_id" list={projectmanage}  title="Project Manager"/>
				<SelectField name="client_id" list={client}  title="Client"/>

		         <Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Start New Project
		          		</Button>

			</Form>

			</>

	)
}

export default Create;