import React ,{useState,useEffect	}from 'react'
import DatePicker from "react-datepicker";
import Header from '../../components/Header';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Progress,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  useDisclosure,
} from '@chakra-ui/react'
import { BsArrowLeftShort } from "react-icons/bs";
import {useLocation,useNavigation } from "react-router-dom";
import emp_user from '../../images/Ellipse 9.png'
import { MdArrowDropDown } from "react-icons/md";

function Veiw() {
	const [selectedMonth, setSelectedMonth] = useState(new Date());

  const handleMonthChange = (date) => {
  	setSelectedMonth(date);
  };


	return(
		<>
		<Header/>
		<div className="Team_Veiw">

			<HStack cursor="pointer" >
 				<Box className="Backedicon" onClick={()=> window.location.href="/team"}>
					<BsArrowLeftShort />
				</Box>
				<Box className="Backed">
					<Text>TimeSheets</Text>
				</Box>
	 		</HStack>


	 		<Box mt="40px">
	 			<HStack justifyContent="space-between" >
	 					<Box w="20%"  cursor="pointer">
				            <HStack border="1px solid #ccc" px="5px" py="5px" borderRadius="7px">
				                <Box>
				                    <Image src={emp_user} alt="Employee User" />
				                </Box>
				                <Box marginLeft="10px">
				                    <Text fontWeight="400" fontSize="24px" color="#000000">
				                        Zaryab <Box display="inline-block" verticalAlign="middle"><MdArrowDropDown /></Box>
				                    </Text>
				                    
				                    <Text fontWeight="400" fontSize="12px" color="#00000080">
				                       Node JS Developer
				                    </Text>
				                </Box>
				            </HStack>
				        </Box>
	 					
	 					<Box w="30%">
	 						<HStack alignItems="center">
	 								<Box>
										 <Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif" borderRadius="4px"
								           >
						          			Review Time sheet
						          		</Button>
									</Box>

	 								<Box>
					          <DatePicker
					            className="MonthPicker"
					            selected={selectedMonth}
					            onChange={handleMonthChange}
					            dateFormat="MMM yyyy"
					            showMonthYearPicker
					            onKeyDown={(e) => e.preventDefault()}
					          />
					        </Box>
	 						</HStack>
	 					</Box>
	 			</HStack>

	 		</Box>


	 		<Box className="Box_Section" mt="20px">
	 			<HStack w="100%" justifyContent="space-between" alignItems="start">
	 				<Box w="70%">
	 					<h1>Calender Veiw</h1>
	 				</Box>
	 				<Box w="30%" border="1px solid #CCCCCC" borderRadius="1px" px="12px" py="2px">
	 					
	 						<Box mt="20px">
		 						<Text fontSize="60px" fontWeight="400" fontFamily="'Montserrat', sans-serif" style={{ lineHeight: '60px' }}>56.00</Text>
		 						<Text fontSize="16px" fontWeight="400" fontFamily="'Montserrat', sans-serif">Total Hours</Text>
	 						</Box>

	 						<Box className="Box_Area">
	 							<Text className="head_text">Hours Breakdown</Text>

	 							<Progress value={80} className="Progress" height='20px' borderRadius="5px" />
	 							<Box mt="10px">
		 							<HStack justifyContent="space-between">
		 								<Box>
		 									<HStack>
		 										<Box w="21px" h="21px" bg="rgba(0, 121, 255, 1)">

		 										</Box>

		 										<Box>
		 											<Text fontSize="12px" fontWeight="400">Development</Text>
		 										</Box>
		 									</HStack>
		 									
		 								</Box>
		 								<Box>
		 									<Text fontSize="12px" fontWeight="400">12</Text>
		 								</Box>
		 							</HStack>
	 							</Box>

	 							<Box mt="10px">
		 							<HStack justifyContent="space-between">
		 								<Box>
		 									<HStack>
		 										<Box w="21px" h="21px" bg="rgba(248, 109, 109, 1)">

		 										</Box>

		 										<Box>
		 											<Text fontSize="12px" fontWeight="400">Research</Text>
		 										</Box>
		 									</HStack>
		 									
		 								</Box>
		 								<Box>
		 									<Text fontSize="12px" fontWeight="400">2</Text>
		 								</Box>
		 							</HStack>
	 							</Box>

	 						</Box>


	 						
	 				

	 				</Box>
	 			</HStack>

	 		</Box>


	 		


	 		


		</div>
		</>
	)
}

export default Veiw;